.description {
    max-height: 100%; /* Limit height to prevent extending */
    text-overflow: ellipsis; /* Show ellipsis for truncated text */
  }

  .price .items {
    padding: 70px;
    text-align: center;
    text-transform: none;
    flex-direction: column;
    box-shadow: inset;
  }
  
  
  .price h1 {
    font-size: 40px;
    margin: 20px 0;
    color: #1eb2a6;
  }
  .price h1 span {
    font-size: 20px;
    margin-right: 5px;
    color: #000;
  }
  .price p {
    color: grey;
    margin: 40px 0;
    font-size: 18px;
  }
  /*--------------faq-------------*/
  .faq .container {
    max-width: 120%;
    border-radius: 5px;
    grid-template-columns: 1fr; 
  }

  @media screen and (min-width: 668px) {
    /* Arrange .items in columns */
    .price {
      display: grid;
      grid-template-columns: repeat(1, 2fr); /* Two columns */
      gap: 80px; /* Adjust gap between items */
    }
  }

  .price .items {
    padding: 20px;
    text-align: center;
    text-transform: none;
    flex-direction: column;
  }
  