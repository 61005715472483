
.faq .box {
  background-color: #fff;
  margin-bottom: 40px;
}
.faq button {
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 15px 20px;
}
.faq h2 {
  font-weight: 500;
}
.faq p {
  padding: 20px;
}
.faq .accordion:hover {
  background-color: #1eb2a6;
  color: #fff;
}

/*--------------faq-------------*/


@media screen and (min-width: 768px) {
  /* Arrange .items in columns */
  .price {
    padding: 10px;
    display: grid;
    font-size: 10px;
    grid-template-columns: 1fr;
    gap: 80px; /* Adjust gap between items */
  }
}
